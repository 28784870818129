import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Container, Row, Col } from "react-bootstrap";

const AboutUs = () => (
  <Layout>
    <SEO title="About Us" />
    <div className="jumbotron">
      <Container>
        <Row>
          <Col>
            <h1>About Us</h1>
          </Col>
        </Row>

        <Row>
          <Col>
            <p>
              Rhylla Devon comprises of 2 holiday cottages; Rhylla Cottage and
              Rhylla Retreat. Both originally copper miner's cottages, parts of
              which date back to the early 1800's.
            </p>
            <p>
              The cottages at Rhylla Devon sit high above the Tamar Valley in an
              area of outstanding natural beauty (AONB). Situated in the small,
              now farming hamlet of Townlake, Rhylla Cottage & Rhylla Retreat
              are only a short walk from the 15th century granite bridge
              crossing the River Tamar connecting Devon with Cornwall.
            </p>
            <p>
              Rhylla Cottage & Rhylla Retreat are in an ideal location for those
              seeking an abundance of local walks, cycling trails and other
              activities. The picturesque scenery of Dartmoor National Park is
              only a short drive away providing opportunities to explore the
              wide open moorlands and deep river valleys.
            </p>
            <p>
              The market town of Tavistock with its assortment of both high
              street and independent shops and famous historic Pannier Market,
              great selection of restaurants and coffee shops is also close by
              (15 minutes).
            </p>
            <p>
              Sitting on the Devon/Cornwall border, Rhylla Cottage & Rhylla
              Retreat provide a central point to venture to the villages and
              beaches of both the north and south coasts of Devon and Cornwall,
              by car.
            </p>
            <p>
              The City of Plymouth with its historic Barbican, The Hoe and Gin
              Distillery can be reached easily over the Tamar Bridge in around
              40 mins and the Eden Project is less than 1 hour away!
            </p>
            <p>
              Locally for good food and pub lovers Rhylla Cottage & Rhylla
              Retreat are perfectly located only a 5/10 minute stroll from The
              Royal Inn, a 15th Century pub, reputedly visited by Charles 1
              during the English Civil War, but now known for its fine ales and
              large portions of excellent food!
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
);

export default AboutUs;
